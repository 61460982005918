@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

:root {
  --text-1: blue;
  --text-2: #280749;
  --text-3: #ffffff;
  --bg-1: #ffffff;
  --bg-2: #280749;
  --bg-3: #501c79;
}

.app__dark_theme {
  --text-1: green;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html,
body {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(180, 35, 35, 0);
}

::selection {
  background-color: var(--bg-2);
  color: #ffffff;
}

.app__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.app__sidebar_container {
  max-width: 225px;
  width: 100%;
  left: 0;
  top: 35px;
  position: fixed;
}

.app__content {
  width: 100%;
  padding: 15px;
  margin-top: 35px;
  position: relative;
  margin-left: 225px;
}

.app__login_screen {
  margin: 0 !important;
  width: 100% !important;
  padding: 0 !important;
}

.test__img_block {
  display: flex;
  width: 100%;
}

.app__data_page {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.app__page_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.app__page_header button {
  background-color: #0a6bff;
  border-radius: 4px;
  border: 0;
  box-shadow: rgba(1, 60, 136, 0.5) 0 -1px 3px 0 inset,
    rgba(0, 44, 97, 0.1) 0 3px 6px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  /* min-height: 56px;
  min-width: 120px; */
  min-width: 75px;
  padding: 8px 12px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.app__page_header button:hover {
  background-color: #065dd8;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .app__page_header button {
    padding: 8px 12px;
    /* min-width: 150px; */
  }

  .app__page_header {
    flex-direction: column;
    gap: 20px;
  }
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

h1 {
  font-size: 24px;
  color: #2a2d3e;
  margin-bottom: 10px;
}

.app__section {
  border-radius: 12px;
  background-color: #eaeaec;
  max-width: 780px;
  width: 100%;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 25px;
}

.app__section_header {
  background-color: #2a2d3e;
  color: #fff;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app__section_header button {
  padding: 6px 16px;
  font-size: 16px;
  background-color: red;
  border: none;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.app__section h3 {
  color: #007bff;
  font-size: 20px;
  font-weight: 500;
}

.app__section_container {
  padding: 10px;
}

.app__section_row {
  display: flex;
  flex-direction: row;
  max-width: 560px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.app__section_cell {
  display: flex;
  flex-direction: column;
  /* max-width: 280px; */
  width: 100%;
  padding: 5px;
}

.app__section_cell_w100 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
}

.app__section_cell_w100 textarea {
  resize: none;
  min-height: 100px;
  border: 1px solid #2a2d3e;
  padding: 10px;
  color: #0056b3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.app__section_container input {
  /* max-width: 260px; */
  border: 1px solid #2a2d3e;
  padding: 10px;
  color: #0056b3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.app__section_container input[type="checkbox"] {
  margin-right: 5px;
}

.app__section_container input:focus {
  border: 1px solid #007bff;
}

.app__section_cell button {
  margin-top: auto;
  background-color: #007bff;
  color: #fff;
  padding: 6px 12px;
  max-width: 200px;
  width: 100%;
  border: 0;
}

.app__rte_container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.app__modal_container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  background: rgba(0, 86, 179, 0.18);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.3px);
  -webkit-backdrop-filter: blur(10.3px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;
}

.app__modal {
  border-radius: 12px;
  background-color: #eaeaec;
  max-width: 780px;
  width: 100%;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 25px;

  min-height: 340px;
}

.app__modal_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.app__modal_header button {
  padding: 6px 16px;
  font-size: 16px;
  background-color: #7a0012;
  border: none;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.app__modal_body {
  padding: 10px;
}

.app__video_container {
  max-width: 270px;
  width: 100%;
}

.app__modal_input_wrapper {
  display: flex;
  flex-direction: column;
}
.app__modal_input_wrapper input {
  max-width: 270px;
  width: 100%;
}

.app__modal_input_wrapper textarea {
  width: 100%;
  height: 240px;
  resize: none;
  margin-bottom: 25px;
  padding: 10px;
}

.app__primary_btn {
  background-color: #0056b3;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 6px 12px;
}

.app__delete_btn {
  background-color: #7a0012;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 6px 12px;
}

.app__success_btn {
  background-color: #1b8466;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 6px 12px;
}

.app__success_btn:disabled,
.app__success_btn[disabled] {
  background-color: #08271e;
  cursor: default;
}

.app__data_table_cell svg {
  font-size: 24px;
  margin-left: 15px;
}

@media screen and (max-width: 768px) {
  .app__sidebar_container {
    display: none;
  }

  .app__content {
    width: 100%;
    padding: 15px;
    margin-top: 35px;
    position: relative;
    margin-left: 0;
  }
}
