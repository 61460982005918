.app__data_table {
  background-color: lightblue;
  max-width: 780px;
  width: 100%;
}

.app__data_table_header {
  background-color: #2a2d3e;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 5px 10px;
}

.app__data_table_row {
  background-color: #b3d7ff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 5px 10px;
}

.app__data_table_row:nth-child(2n) {
  background-color: #e6f2ff;
}

.app__data_table_cell {
  padding: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
}

/* SAMPLE 
 <div className="app__data_table">
        <div className="app__data_table_header">
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell"></div>
        </div>
        <div className="app__data_table_row">
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell"></div>
        </div>
        <div className="app__data_table_row">
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell"></div>
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell">adat</div>
          <div className="app__data_table_cell">adat</div>
        </div>
      </div> */
